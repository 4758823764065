import {createLogger} from 'redux-logger';
import {createBrowserHistory} from 'history'
import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import {composeWithDevTools} from "redux-devtools-extension";

export const history = createBrowserHistory();
export const logger = createLogger();

export default function configureStore(reducer, preloadedState) {
    const store = createStore(
        reducer,
        preloadedState,
        composeWithDevTools(applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk
        )),
    );

    return store
}
