import React from 'react';
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            messages: [
                "We’re working hard to make this website available again.",
                "Sorry for the dust! We know it’s taking a while but sit tight and we’ll be with you soon.",
                "Shhh… don’t tell anyone.",
            ]
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    _randomIntFromInterval = (min, max) => { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    render() {
        let url = "https://picsum.photos/" + this.state.width + "/" + this.state.height

        return <div className="main-area-wrapper">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Coming soon '{window.location.hostname}'</title>
                <meta name="description" content={"Coming soon " + window.location.hostname}/>
            </Helmet>
            <div className="main-area center-text" style={{backgroundImage: "url(" + url + ")"}}>
                <div className="display-table">
                    <div className="display-table-cell">
                        <h1 className="title"><b>Coming Soon</b></h1>
                        <p className="desc font-white">
                            {this.state.messages[this._randomIntFromInterval(0, (this.state.messages.length - 1))]}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        profile: state.profile,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(HomePage));
