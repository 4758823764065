import React from 'react';
import {Route, withRouter} from "react-router";
import {Switch} from 'react-router-dom';
import HomePage from './react/HomePage'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

class App extends React.Component {

    render() {
        return <Switch>
            <Route component={HomePage}/>
        </Switch>
    }
}

function mapStateToProps(state) {
    return {
        profile: state.profile,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));
