import React from "react";
import ReactDOM from "react-dom";

import "./style/App.scss";

import App from "./App";
import combinedReducers from "./react/redux/reducer";
import configureStore, {history} from "./react/redux/store";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import ReduxToastr from 'react-redux-toastr'

const rootElement = document.getElementById("root");
const reducer = combinedReducers(history);
window['redux'] = configureStore(reducer);

if (rootElement) {
    ReactDOM.render(<Provider store={window['redux']}>
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-left"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick/>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>, rootElement);
} else {
    throw new Error("Could not find root element to mount to!");
}
